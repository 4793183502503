@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Poppins', sans-serif;
}

.perspective-1000 {
  perspective: 1000px;
}

.skew-left {
  transform: perspective(1000px) rotateY(12deg);
}

.skew-right {
  transform: perspective(1000px) rotateY(-12deg);
}

.hover\:skew-none:hover {
  transform: perspective(1000px) rotateY(0deg);
}